<template>
  <div class="buy-detaile">
    <common-header title="充值详情" />
    <CumulativeCard title="累计：" :amount="amountSum" />
    <div class="account-table">
      <h4>明细</h4>
      <van-list
        class="account-list"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="table-title">
          <p class="time">时间</p>
          <p class="comput-power">FIL</p>
          <p class="address">状态</p>
        </div>
        <div class="table-title-item" v-for="item in list" :key="item._id">
          <p class="time">{{ item.createdAt }}</p>
          <p class="comput-power">{{ item.amount }}</p>
          <p :class="['type', item.className]">{{ item.progress }}</p>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import CommonHeader from "../../components/CommonHeader.vue";
import CumulativeCard from "../../components/CumulativeCard.vue";
import { deposit } from "../../server";
import * as timeago from "timeago.js";
export default {
  name: "MyCommunity",
  components: { CommonHeader, CumulativeCard },
  data() {
    return {
      currentId: 0,
      loading: false,
      finished: true,
      current: 1,
      amountSum: 0,
      list: [],
      status: {
        4: "成功",
        1: "正在上链",
        5: "失败",
      },
      statusClassNames: {
        4: "success",
        1: "pending",
        5: "fail",
      },
    };
  },

  methods: {
    async onLoad() {
      this.current = this.current + 1;
    },
    async getDeposit() {
      const data = await deposit(this.getAddress);
      this.amountSum = data.amountSum;
      this.list = data.list.map((item) => {
        return {
          ...item,
          createdAt: timeago.format(item.createdAt, "zh_CN"),
          className: this.statusClassNames[item.progress],
          progress: this.status[item.progress],
        };
      });
    },
  },
  async created() {
    await this.getDeposit();
  },
};
</script>

<style lang="less" scoped>
.buy-detaile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .cumulative-card {
    margin-top: 15px;
  }
  .account-table {
    width: 345px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    h4 {
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 15px;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: bold;
      color: #e9ecf0;
    }
    .account-list {
      margin-bottom: 30px;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 15px;
      width: 345px;
      height: 306px;
      background: #161f2c;
      opacity: 1;
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;

      overflow-y: scroll;
      .table-title,
      .table-title-item {
        flex-shrink: 0;
        width: 100%;
        height: 37px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #263243;
        font-size: 12px;
        font-family: "PingFang SC";
        font-weight: 400;
        color: #c1c1c1;
        .time {
          width: 134px;
        }
        .comput-power {
          width: 133px;
        }
        .address {
          flex-grow: 1;
        }
      }
      .table-title-item {
        .time,
        .comput-power {
          color: #e9ecf0;
        }
        .pending {
          color: #5399fd;
        }
        .success {
          color: #019969;
          text-align: right;
        }
        .fail {
          color: red;
        }
      }
    }
  }
}
</style>
